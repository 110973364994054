:root {
  --white_A700_7f: #ffffff7f;
  --black_900_b2: #000000b2;
  --gray_900: #18150a;
  --lime_900: #a35c07;
  --white_A700_66: #ffffff66;
  --yellow_900_66: #f7931a66;
  --yellow_900_33: #f7931a33;
  --black_900_01: #000000;
  --yellow_900_00: #f7931a00;
  --orange_50: #fff0e0;
  --black_900_99: #00000099;
  --black_900: #090909;
  --lime_900_01: #9d561e;
  --gray_900_b2: #021719b2;
  --yellow_900: #f7931a;
  --white_A700_19: #ffffff19;
  --yellow_900_19: #f7931a19;
  --blue_200: #a1d5ff;
  --white_A700: #ffffff;
  --deep_purple_A200_33: #794eff33;
  --white_A700_7f: #ffffff7f;
  --white_A700_b2: #ffffffb2;
  --black_900_b2: #000000b2;
  --deep_purple_900: #250685;
  --deep_purple_A200_19: #794eff19;
  --deep_purple_A200_a3: #794effa3;
  --deep_purple_A200_19: #794eff19;
  --black_900_01: #000000;
  --black_900: #090909;
  --deep_purple_A100_cc: #a588ffcc;
  --yellow_900: #f7931a;
  --purple_900: #3d2a70;
  --white_A700_19: #ffffff19;
  --deep_purple_A400: #4616ff;
  --deep_purple_A100: #a681ff;
  --deep_purple_A200: #794eff;
  --deep_purple_A200_00: #794eff00;
  --gray_900: #111331;
  --deep_purple_900_01: #200085;
  --deep_purple_A200_d3: #794effd3;
  --deep_purple_A200_7f: #794eff7f;
  --white_A700_66: #ffffff66;
  --orange_50: #fff0e0;
  --black_900_b2_02: #120219b2;
  --black_900_b2_01: #0c0219b2;
  --gray_900_b2: #021719b2;
  --blue_200: #a1d5ff;
  --deep_purple_A200_96: #794eff96;
  --white_A700: #ffffff;
  --deep_purple_A200_33: #794eff33;
  --deep_purple_A200_cc: #794effcc;
  --light_purple_A200_cc: rgba(121, 78, 255, 0.64);
}
