.closed {
  display: none;
}

.new_menu {
  height: 100%;
  width: 80%;
  z-index: 100000;
  position: absolute;
  padding: 2rem;
  top: 0;
  right: 0;
  transition: 0.4s ease left;
  border-top-left-radius: 1.5rem;
  border: 1.35px solid #794eff;
  background: linear-gradient(178deg, rgba(30, 20, 64, 0.87) 13.86%, rgba(36, 26, 73, 0.87) 98.67%);
}

.side_menu .right_section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.side_overlay {
  -webkit-box-align: center;
  align-items: center;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  visibility: visible;
  z-index: 1;
  /* background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px); */
  background: rgba(22, 22, 22, 0.8);
  backdrop-filter: blur(2.25px);
  opacity: 1;
}

.side_menu.closed {
  pointer-events: none;
}

.side-menu.closed > .side_overlay {
  background: rgba(0, 0, 0, 0);
}

.side_menu.closed > .side_menu {
  left: -200px;
}

.side_header {
  display: flex;
  justify-content: flex-end;
  color: #b6b6b6;
  font-size: 24px;
  padding-bottom: 12px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.3); */
}

.token_details_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.token_details_container .title {
  font-size: 16px;
  font-weight: 700;
}

.token_details_container .title div {
  font-size: 12px;
  font-weight: 400;
}
